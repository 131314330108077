import SectionTitle from 'components/SectionTitle'
import { useAuth } from 'contexts/auth'
import { Box, chakra, Flex, RadioGroup, Text, VStack } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { formatDateUTC } from 'helpers/formatTimer'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Icon } from '@iconify/react'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'
import { useResetProjectX } from 'features/project-x/queries/use-mutation-reset-px'
import { ModalRevisionReset } from 'components/Modal/ModalRevisionReset'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useResetRevision } from 'components/Modal/ModalRevisionReset/use-mutation-reset'
import Swal from 'sweetalert2'
import { schema } from 'components/Modal/ModalRevisionReset/schema'
import { useUpdateProfile } from '../ProfileTab/mutations/mutate-profile'
import { FieldRadio } from 'components/Fields/FieldRadio'

interface FormReset {
  inicialDate: Date | undefined
  finalDate: Date | undefined
}

export default function ResetsTab() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalResetProjectX, setModalResetProjectX] = useState(false)
  const [modalResetAnnualRevision, setModalResetAnnualRevision] = useState(
    false,
  )
  const [confirmResetAnnualRevision, setConfirmResetAnnualRevision] = useState(
    false,
  )
  const [goalType, setGoalType] = useState<string | undefined>(undefined)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { user, isFocusLearningActivated } = useAuth()
  const showToast = useToastMessage()
  const resetProjectXMutation = useResetProjectX()
  const mutateReset = useResetRevision()
  const updateProfileMutation = useUpdateProfile()

  const { updateUserData } = useAuth()

  const methods = useForm<FormReset>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  /*
  |-----------------------------------------------------------------------------
  | CallBacks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const SubmitFormGoalType = async (data: {
    goalType: 'VETERAN' | 'NEWBIE'
  }) => {
    try {
      if (!data?.goalType || !user?.profile?.id) return

      await updateProfileMutation.mutateAsync({
        data: {
          goalType: data.goalType,
        },
        id: user.profile.id,
      })

      showToast({
        title: 'Sucesso',
        description: 'Tipo de meta alterada com sucesso.',
        type: 'success',
        duration: 5000,
        mode: 'dark',
      })
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível alterar o tipo da meta'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  const SubmitForm = async (data: FormReset) => {
    try {
      if (data.inicialDate && data.finalDate) {
        await mutateReset.mutateAsync({
          startDate: format(data.inicialDate, 'yyyy-MM-dd'),
          endDate: format(data.finalDate, 'yyyy-MM-dd'),
        })

        Swal.fire({
          buttonsStyling: false,
          title: 'Sucesso',
          html:
            '<p style="text-align:center">Registros deletados com  sucesso!</p>',
          icon: 'success',
          didClose: () => {
            setConfirmResetAnnualRevision(false)
            setModalResetAnnualRevision(false)
          },
        })
      }
    } catch (error: any) {
      const message =
        error?.response?.data?.errors
          ?.map((error: any) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível efetuar o registro'

      Swal.fire('Aviso', `<p style="text-align:center">${message}</p>`, 'error')
    } finally {
      setConfirmResetAnnualRevision(false)
      updateUserData()
    }
  }

  const resetProjectX = useCallback(async () => {
    try {
      await resetProjectXMutation.mutateAsync()

      showToast({
        title: 'Sucesso',
        description: 'Projeto-X Resetado com sucesso',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error: any) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível resetar o projeto-X.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    } finally {
      setModalResetProjectX(false)
    }
  }, [resetProjectXMutation, showToast])

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (user?.profile?.goal_type) {
      setGoalType(user?.profile?.goal_type)
    }
  }, [user?.profile?.goal_type])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack alignItems={'flex-start'} gap={'1rem'}>
      <Box>
        <SectionTitle>Escolha sua Meta</SectionTitle>

        <RadioGroup
          onChange={(goalType: 'VETERAN' | 'NEWBIE') =>
            SubmitFormGoalType({ goalType })
          }
          value={goalType}
        >
          <Flex flexDir={'column'} gap={'1rem'}>
            <FieldRadio name="sim" value={'NEWBIE'}>
              Novato
            </FieldRadio>
            <FieldRadio name="não" value={'VETERAN'}>
              Veterano
            </FieldRadio>
          </Flex>
        </RadioGroup>
      </Box>

      {!user?.is_revision_reset_executed && user?.isVeteranStudent && (
        <>
          <SectionTitle>RESET GERAL DA PLATAFORMA</SectionTitle>

          <DefaultButton
            label="Resetar revisões"
            leftIcon={
              <Icon
                fontSize={'16px'}
                color="#E56000"
                icon={'eva:refresh-fill'}
              />
            }
            iconSpacing="8px"
            maxW={{ base: '100%', sm: '220px' }}
            w="100%"
            variant="primary"
            onClick={() => setModalResetAnnualRevision(true)}
          />
        </>
      )}

      {user?.is_revision_reset_executed && (
        <>
          <Text
            fontFamily={'Mulish'}
            fontSize="16px"
            fontWeight={'600'}
            color={'#FFFFF'}
            lineHeight="19px"
          >
            Dia do resete:{' '}
            <chakra.span fontWeight={'400'}>
              {user?.revisionResetHistory?.[0]?.created_at &&
                formatDateUTC(user?.revisionResetHistory?.[0]?.created_at)}
            </chakra.span>
          </Text>
          <Text
            fontFamily={'Mulish'}
            fontSize="16px"
            fontWeight={'600'}
            color={'#FFFFF'}
            lineHeight="19px"
            mt="8px"
          >
            Período do resete:{' '}
            <chakra.span fontWeight={'400'}>
              {user?.revisionResetHistory?.[0]?.starts_at &&
                user?.revisionResetHistory?.[0]?.finishes_at &&
                `${formatDateUTC(
                  user?.revisionResetHistory?.[0]?.starts_at,
                )} a ${formatDateUTC(
                  user?.revisionResetHistory?.[0]?.finishes_at,
                )}`}
            </chakra.span>
          </Text>
        </>
      )}

      {isFocusLearningActivated &&
        user?.profile?.trial_year === new Date().getFullYear() && (
          <>
            <SectionTitle>RESET DO PROJETO-X</SectionTitle>
            <DefaultButton
              label="Limpar revisões"
              onClick={() => setModalResetProjectX(true)}
              variant="primary"
              maxW={{ base: '100%', sm: '220px' }}
              w="100%"
              iconSpacing={'10px'}
              leftIcon={
                <Icon
                  color="#E56000"
                  fontSize={'16px'}
                  icon={'eva:archive-outline'}
                />
              }
            />
          </>
        )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(SubmitForm)}>
          <ModalDefaultTwoButton
            isOpen={modalResetProjectX}
            onClose={() => setModalResetProjectX(false)}
            onConfirmClick={resetProjectX}
            subTitle="Se continuar, as revisões agendadas serão excluídas."
            confirmButtonIsLoading={resetProjectXMutation.isLoading}
            title="Tem certeza que deseja limpar as revisões?"
          />

          <ModalRevisionReset
            isOpen={modalResetAnnualRevision}
            onClose={() => setModalResetAnnualRevision(false)}
            onCloseIcon={() => setModalResetAnnualRevision(false)}
            onConfirmClick={async () => {
              const [finalDate, inicialDate] = await Promise.all([
                methods.trigger('finalDate'),
                methods.trigger('inicialDate'),
              ])

              if (!finalDate || !inicialDate) return

              setConfirmResetAnnualRevision(true)
              setModalResetAnnualRevision(false)
            }}
          />

          <ModalDefaultTwoButton
            isOpen={confirmResetAnnualRevision}
            onClose={() => setConfirmResetAnnualRevision(false)}
            onConfirmClick={async () => {
              await SubmitForm({
                finalDate: methods.watch('finalDate'),
                inicialDate: methods.watch('inicialDate'),
              })
            }}
            subTitle="Tem certeza que deseja resetar as revisões? Essa ação é irreversível e não poderá ser desfeita."
            confirmButtonIsLoading={resetProjectXMutation.isLoading}
            title="Tem certeza que deseja limpar as revisões?"
          />
        </form>
      </FormProvider>
    </VStack>
  )
}
